import React from 'react'
import './hero.css'
import Spinner from  '../../assets/HM logo animation.gif'
import { motion } from "framer-motion"

const Hero = () => {
  return (
    <div className="hero">
        <div className="hero-text">
            <motion.div 
                className="heading"
                initial={{ y: 400 }}
                whileInView={{ y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, type: "spring", stiffness: 120 }}
            >
                <h1>HONEST <br/> MARKETING</h1>
            </motion.div>
            <motion.div 
                className="sub-text"
                initial={{ x: 300, opacity:0 }}
                whileInView={{ x: 0, opacity:1 }}
                viewport={{ once: true }}
                transition={{ delay: 1.5, duration: 0.5, type: "spring", stiffness: 120 }}
            >
                <p>On Demand Marketing Specialists</p>
            </motion.div>
        </div>
        <div className="spinner-logo">
            <img src={Spinner} alt="spinner-logo" />
        </div>
    </div>
  )
}

export default Hero