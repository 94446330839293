import React from 'react'
import JumiaSnip from '../../assets/jumia.png'
import './jumia.css'

const Jumia = () => {
  return (
    <div className="jumia-section">
        <div className="jumia-partner">
            <div className='jumia-textBox'>
                <div>
                    <p className="jumia-heading">Jumia</p>
                </div>
                <div className="jumia-subHeading">
                    <p>The Jumia Food app makes it simple for anybody to use a mobile device to look over the menu of the best vendors and place an order for home delivery. Jumia Food is the most convenient online food ordering site, connecting people with the best restaurants & Grocery stores  around them.</p>
                </div>
                <div className="jumia-paragraph-text">
                    <p>Awareness & Brand Equity</p>
                    <ul>
                        <li>Jumia Advertising reaches 78% of all online shoppers in Africa. over 1b page  views per year, and million monthly  Active Users.</li>
                        <li>Consideration & Intent</li>
                        <li>With up to .8% CTR across platform, you are sure to have shoppers engage with  your ads and visit your Shop in Shop.</li>
                        <li>Sales Uplift & ROAS</li>
                        <li>Jumia Advertising achieves an average ROAS of 3.5-7 for our Sellers.</li>
                    </ul>
                </div>
            </div>
            <div className='jumia-snips'>
                 <img src={JumiaSnip} alt="Mobile Screenshots" />
            </div>
        </div>
    </div>
  )
}

export default Jumia