import React from 'react'
import VulekaSnip from '../../assets/vuleka.png'
import './vuleka.css'

const Vuleka = () => {
  return (
    <div className="vuleka-section">
        <div className='vuleka-heading-mobile'>
            <p className="vuleka-heading">Vuleka</p>
        </div>
        <div className="vuleka-partner">
            <div className='vuleka-textBox'>
                <div className='vuleka-heading-desktop'>
                    <p className="vuleka-heading">Vuleka</p>
                </div>
                <div className="vuleka-paragraph-text">
                    <ul>
                        <li>Vuleka is an e-commerce micodistributor platform that allows spaza shop owners to quickly and easily purchase products (stock). By working with Vuleka, you can sell your products straight to the township market.</li>
                        <li>This also helps keeps prices more affordable for consumers and counteracts the circulation of counterfeit products.</li>
                        <li>Vuleka has a network of 5000+ spaza shops in Gauteng, which we can also brand and add Wi-Fi too.</li>
                        <li>Consumers are going to choose brands that allow them to shop conveniently. Therefore, especially in townships, spaza shops act as a vital space to target consumers and create brand awareness.</li>
                        <li>Thus, Vuleka helps with accessibility and actually bringing your product to the township.</li>
                    </ul>
                </div>
            </div>
            <div className='vuleka-snips'>
                 <img src={VulekaSnip} alt="Mobile Screenshots" />
            </div>
        </div>
    </div>
  )
}

export default Vuleka