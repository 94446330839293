import React from 'react'
import OneCartImage from '../../assets/mobile-mocks.png';
import MobileSnips from '../../assets/mobile-snips.png';
import SpazaShop from '../../assets/spaza-shop.jpeg'
import './partners.css'

const Partners = () => {
  return (
    <div className="partners">
        <div className="township-media">
            <div className="township-text-block">
                <div>
                    <p className="township-heading">Honest Township Media</p>
                </div>
                <div className="township-text">
                    <p>Internet connectivity and adoption are some of the prevalent barriers for consumers in townships. </p>
                    <p>As well as a lack of trust and education, thus giving brands limited access to these consumers. ​Honest Township Media focuses on bridging the gap between consumers and brands by addressing these barriers.</p>
                    <div>
                        <ul>
                            <li>We have a catalogue of products aimed at bringing your brand closer to the community.</li>
                            <li>Our products include:
                                <ul>
                                    <li>Branded Spaza Shop or Wall Murals in high traffic areas</li>
                                    <li>Kazang Point Of Sale Receipt Marketing</li>
                                    <li>Moya app advertising.</li>
                                </ul>
                            </li>
                            <li>In addition, we have a huge spaza shop network across South Africa. Through this network, we have Free Wi-Fi enabled to residence in the area, which we use as a medium of advertising.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="township-image">
                <img src={SpazaShop} alt="Spaza Shop" />
            </div>
        </div>
        <div className="avo-section">
            <div className="avo-partner">
                <div className='avo-textBox'>
                    <div>
                        <p className="avo-heading">Avo</p>
                    </div>
                    <div className="avo-subHeading">
                        <p>How our Avo platform helps brands remain relavent and response?</p>
                    </div>
                    <div className="avo-paragraph-text">
                        <p>Avo is a data-driven Lifestyle E-commerce app by Nedbank that consolidates major retailers such as Makro, Pick n pay, Ngf, Blue bottles, Clicks etc... In order to provide the consumer with a centralized one basket purchase system </p>
                        <p>Avo has over 1.5 million users, as well as access to Nedbank clients. Moreover, we are able to hyper target consumers based on Demographic, Behavioural, & Digital segmentation.</p>
                    </div>
                </div>
                <div className='avo-snips'>
                    <img src={MobileSnips} alt="Mobile Screenshots" />
                </div>
            </div>
        </div>
        <div className="one-cart">
            <div className="one-cart-heading">
                <p className="heading">OneCart</p>
                <p className="heading-text">OneCart is an online shopping platform. OneCart has partnered with numerous malls and the retailers, providing ultimate convenience and flexibility to the online consumer. OneCart has almost 500k users </p>
            </div>
            <div className="one-cart-image">
                <img src={OneCartImage} alt="OneCart Image"/>
            </div>
        </div>
    </div>
  )
}

export default Partners