import React from 'react'
import './contact.css'
import Email from '../../assets/icons8-email-50.png'
import Phone from '../../assets/icons8-phone-50.png'

const Contact = () => {
  return (
    <div className="contact-page">
        <div className="contact-headings">
            <h1>LETS CONNECT</h1>
            <h4>We'd love to hear from you.</h4>
        </div>
        <div className="contact-blocks">
            <div className="contact-info-block blockA">
              <div className='emails'>
                <h3 className='contact-heading bookings'>BOOK WITH US:</h3>
                <div>
                  <div className='details'>
                    <img src={Email} alt="Email Icon"/>
                    <p>bongakele@honestmarketing.co.za</p>
                  </div>
                  <div className='details'>
                    <img src={Phone} alt="Email Icon"/>
                    <p>(+27) 78 772 7540</p>
                  </div>
                </div>
                <h3 className='contact-heading newClients'>NEW CLIENTS:</h3>
                <div>
                  <div className='details'>
                    <img src={Email} alt="Email Icon"/>
                    <p>tish@honestmarketing.co.za</p>
                  </div>
                  <div className='details'>
                    <img src={Phone} alt="Email Icon"/>
                    <p>(+27) 78 772 7540</p>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="contact-info-block blockB">
              <form className="contact-form ">
                <div className="form-inputs names">
                  <div className="firstName">
                    <label htmlFor="name">First Name</label>
                    <input type="text" name="name"/>
                  </div>
                  <div className="lastName">
                    <label htmlFor="surname">Last Name</label>
                    <input type="text" name="surname"/>
                  </div>
                </div>
                <div className="form-inputs emailAddress">
                  <label htmlFor="email">Email*</label>
                  <input type="email" name="email"/>
                </div>
                <div className="form-inputs message">
                  <label htmlFor="message">Message</label>
                  <textarea name="message"></textarea>
                </div>
                <button>Send</button>
              </form>
            </div>
        </div>
    </div>
  )
}

export default Contact