import React from 'react'
import './our-partners.css'

const OurPartners = () => {
  return (
    <div className="our-partners" id="partners">
        <div className="text-container">
            <div className="heading-div">
                <h1>OUR</h1> 
                <h4>PARTNERS</h4>
            </div>
            <div className='partners-div'>
                <div className="partners-text avo">AVO</div>
                <div className="partners-text township">HONEST TOWNSHIP MEDIA</div>
                <div className="partners-text onecart">ONE CART</div>
            </div>
        </div>
    </div>
  )
}

export default OurPartners