import React from 'react'
import Email from '../../components/email/Email'
import Hero from '../../components/hero/Hero'
import Jumia from '../../components/jumia/Jumia'
import Partners from '../../components/partners/Partners'
import Welcome from '../../components/section2/Welcome'
import WhatWeDo from '../../components/section3/WhatWeDo'
import Blocks from '../../components/section4/Blocks'
import OurPartners from '../../components/section5/OurPartners'
import Vuleka from '../../components/vuleka/Vuleka'

const Home = () => {
  return (
    <div className="home">
        <Hero/>
        <Welcome/>
        <WhatWeDo/>
        <Blocks/>
        <OurPartners/>
        <Partners/>
        <Jumia/>
        <Vuleka/>
        {/* <Email/> */}
    </div>
  )
}

export default Home