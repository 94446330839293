import React from 'react'
import './welcome.css'

const Welcome = () => {
  return (
    <div className="welcome-section">
        <div className="welcome-title-div">
            <h1 className="welcome-title">WELCOME TO OUR SITE</h1>
        </div>
        <div className="welcome-desc-div">
            <p>We are a Data-driven on-demand Solutions agency that aims to drive brand growth, relevancy, and agility.</p>
        </div>
    </div>
  )
}

export default Welcome