import React from 'react'
import './blocks.css'

//images
import Heineken from '../../assets/heineken-logo.png'
import Maggi from '../../assets/maggi-logo.png'
import Nestle from '../../assets/nestle-logo.png'
import Knorr from '../../assets/knorr-logo.png'

const Blocks = () => {
  return (
    <div className="blocks-section">
        <div className="blocks">
            <div className="block one">
                <div className="block-heading">
                    <h1>01</h1>
                    <h6>Advertising</h6>
                </div>
                <div className="block-desc">
                    <p>It is not just about being Always On. It’s also about being Always Relevant and Always Responsive to customers’ desire for convenience and ubiquity.We increase brand salience through strategic placements of ads either on Avo, OneCart, Moya or our out of home media (OOHM).</p>
                </div>
            </div>
            <div className="block two">
                <div className="block-heading">
                    <h1>02</h1>
                    <h6>Optimization</h6>
                </div>
                <div className="block-desc">
                    <p>Sales optimization - We are able to track sales across our platforms. Moreover, through redeemable coupons, sampling & tracking of conversions we are able to drive conversions and return of investment (ROI)</p>
                    <p>Product Optimization - In terms of category & product optimization, we drive traffic through understanding audiences and carefully selecting keywords. We make sure that your brand or products are featured at the epicenter of our platforms.</p>
                </div>
            </div>
            <div className="block  three">
                <div className="block-heading">
                    <h1>03</h1>
                    <h6>Creative</h6>
                </div>
                <div className="block-desc">
                    <p>Working in collaboration with our creative team, we can help design new unique and targeted artwork that speaks directly to the intended audience. This includes working on the visual elements of your campaign, copy writing or creating strong call to action (CTA’s) that speak to the customers.</p>
                </div>
            </div>
            <div className="block four">
                <div className="block-heading">
                    <h1>04</h1>
                    <h6>Reporting</h6> 
                </div>
                <div className="block-desc">
                    <p>A primary goal of ours is to measure your progress and subsequent marketing performance. Thus, we  are able to compile and organize your data into summaries, post-campaign analysis and performance reports (weekly or monthly).</p>
                </div>
            </div>
        </div>
        <div className="clients" id="clients">
            <div className="client-heading">
                <h1>Clients</h1>
                <p>Some of the brands we work with</p>
            </div>
            <div className="client-logos">
                <div className="logo knorr">
                    <img src={Knorr} alt="" />
                </div>
                <div className="logo">
                    <img src={Heineken} alt="" />
                </div>
                <div className="logo">
                    <img src={Maggi} alt="" />
                </div>
                <div className="logo">
                    <img src={Nestle} alt="" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Blocks