import React from 'react'
import './email.css'
import Logo from '../../assets/HM logo animation.gif'

const Email = () => {
  return (
    <div className="emailSection">
        <div className="email">
            <h2 className="slash">/</h2>
            <div className="contact-details">
              <h3>tish@honestmarketing.co.za</h3>
              <h3>(+27) 71 709 835</h3>
            </div>
        </div>
        <div className="imageBox">
            <img src={Logo} alt="HM logo"/>
        </div>
    </div>
  )
}

export default Email