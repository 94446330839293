import React from 'react'
import { motion } from "framer-motion";
import './what-we-do.css'

const WhatWeDo = () => {
  return (
    <div className="what-we-do-section" id="what-we-do-section">
        <motion.div className="what-we-do-heading-block">
            <h4 className="what-we-do-heading">WHAT DO WE DO</h4>
        </motion.div>
        <div className="what-we-do-desc-block">
            <p className='what-we-do-desc'>We utilize our various platforms to strategically place your content, targeting consumers directly.  Thus, we help you gain access to particular audiences.</p>
            <p className='what-we-do-desc'>We also make use of audience analytics pertaining to: market demographics, purchasing behaviours, customer loyalty and returns, and retail details.</p>
        </div>
    </div>
  )
}

export default WhatWeDo