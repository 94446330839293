import React, {useState} from 'react'
import './navbar.css'
import Logo from '../../assets/HM-logo.png'
import Menu from '../../assets/icons8-menu-48.png'
import MenuClose from '../../assets/icons8-close-50.png'
import { Link, useNavigate } from 'react-router-dom'

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const GoHome = () => {
    navigate('/') 
  }


  return (
    <div className='navbar'>
        <div className='navbar-logo'>
            <div className='navbar-slash'>/</div>
            <div className='navbar-logo-img' onClick={GoHome}>
              <img src={Logo} alt="Honest Marketing Logo" />
            </div>
        </div>
        <div className='menu-icon' onClick={() => setOpen(!open)}>
          {!open ? <img src={Menu} alt="menu" /> :
          <img src={MenuClose} alt="menu close" />}
        </div>
        <div className={open ? 'navbar-mobile-links navbar-links' : 'navbar-links'}>
            <ul className='nav-ul'>
                <li className='nav-link' onClick={() => setOpen(!open)}>
                  <a href="https://honestmarketing.co.za/#what-we-do-section">About</a>
                </li>
                <li className='nav-link' onClick={() => setOpen(!open)}>
                  <a href="https://honestmarketing.co.za/#partners">Our Partners</a>
                </li>
                <li className='nav-link'  onClick={() => setOpen(!open)}>
                  <a href="https://honestmarketing.co.za/#clients">Clients</a>
                </li>
                <Link onClick={() => setOpen(!open)} className='nav-link' to='/contact'>Get in touch</Link>
            </ul>
        </div>
    </div>
  )
}

export default Navbar