import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <div className="footer">
        <p>©2023 by Honest Marketing Pty (Ltd)</p>
    </div>
  )
}

export default Footer