import { Routes, Route } from 'react-router-dom';
import './App.css';
import Email from './components/email/Email';
import Footer from './components/footer/Footer';
import Navbar from './components/navbar/Navbar';
import Contact from './pages/contact/Contact';
import Home from './pages/home/Home';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Routes>
        <Route exact path='/' element={<Home/>} />
        <Route path='/contact' element={<Contact/>} />
      </Routes>
      <Email/>
      <Footer/>
    </div>
  );
}

export default App;
